let config = {

  // default country set
  DefaultCountrySelectedInMobile: "in",
  // default language 
  DefaultLanguage: "en",

  //google Places key
  YOUR_GOOGLE_MAPS_API_KEY: "AIzaSyCqjcz18zgylo0z1nKx7pBl8yJ_rl4vchc",

};

export default config;
